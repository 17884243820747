import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, {useState, useEffect} from 'react';
import './App.css';
import Post from './Post'
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import {Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Switch } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
// import { useRadioGroup } from '@mui/material/RadioGroup';
import Paper from '@mui/material/Paper';
// import makeStyles from '@mui/styles/makeStyles';
// import { makeStyles } from 'tss-react/mui';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Input, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@mui/material';
// import {SlideDown} from 'react-slidedown'
// import 'react-slidedown/lib/slidedown.css'
// import Box from './components/Box/box'
// import SlideIn from './components/Slidein/Slidein'

import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import ImageUpload from './ImageUpload';
import useLocalStorage from 'use-local-storage';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

// import { ClassNames } from '@emotion/react';

// create a const that references the base url for the project
// const BASE_URL = 'http://localhost:8000/'
const BASE_URL = 'https://api.cbdpower.ca/' 



// define styles for modal below
function getModalStyle() {
  const top = 50;
  const left = 50;

  return{
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: `absolute`,
    overflow:'scroll',
    width: 400,
      border: '2px solid #222',
  };
}


function App({like}) {

// add theme.paper styles to the app so they appear in the modal
// const classes = useStyles();
// add a state here for POSTS - useState set to an empty array
const [posts, setPosts] = useState([]);
// SET STATES add sign in / out constants here - openSignIn / openSignUp will be a sign in window closed by default (useState(false))
const [openSignIn, setOpenSignIn] = useState(false);
const [openSignUp, setOpenSignUp] = useState(false);
const [openNewPost, setOpenNewPost] = useState(false);
const [openLikeList, setOpenLikeList] = useState(false);
const [openLikeListV2, setOpenLikeListV2] = useState(false);
// const [checked, setChecked] = useState(false);
const [activeStates, setActiveStates] = useState({});

const [openCoa, setOpenCoa] = useState(false);
const [likes, setLikes] = useState([]);
const [wants, setWants] = useState([]);
const [modalStyle, setModalStyle] = useState(getModalStyle);
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [authToken, setAuthToken] = useState(null);
const [authTokenType, setAuthTokenType] = useState(null);
const [userId, setUserId] = useState('');
const [email, setEmail] = useState('');
const [firstname, setFirstname] = useState('');
const [lastname, setLastname] = useState('');
const [phonenum, setPhonenum] = useState('');
const [cpnyname, setCpnyname] = useState('');
// const [liker_cpnyname, setLiker_cpnyname] = useState('');
// const [liker_email, setLiker_email] = useState('');
// const [liker_firstname, setLiker_firstname] = useState('');
// const [liker_lastname, setLiker_lastname] = useState('');
// const [liker_phonenum, setLiker_phonenum] = useState('');
const [website, setWebsite] = useState('');
const [usertype, setUsertype] = useState('');
const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
const [themeld, setThemeld] = useLocalStorage('themeld', defaultDark ? 'dark' : 'light');
// const [startAnimation, setStartAnimation] = useState(false);
const [imageUrl, setImageUrl] = useState('')


// retrieve authToken, authTokenType, UserId, username from localStorage 
useEffect(() => {
  setAuthToken(window.localStorage.getItem('authToken'));
  setAuthTokenType(window.localStorage.getItem('authTokenType'))
  setUsername(window.localStorage.getItem('username'))
}, []) 

// Store auth token and user id in local storage
// useEffect is dependent on authToken, authTokenType, userId (run this function whenever those change)
useEffect(() => {
  authToken
    ? window.localStorage.setItem('authToken', authToken)
    : window.localStorage.removeItem('authToken')
  authTokenType
    ? window.localStorage.setItem('authTokenType', authTokenType)
    : window.localStorage.removeItem('authTokenType')
  username
    ? window.localStorage.setItem('username', username)
    : window.localStorage.removeItem('username')
  userId
    ? window.localStorage.setItem('userId', userId)
    : window.localStorage.removeItem('userId')
    
}, [authToken, authTokenType, userId, username])

// function to open the coa modal - this gets passed to the Post component
// function openCoaModal() {
//   setOpenCoa(true);
// }
const openCoaModal = (post) => {
  if (post.image_url_type == 'absolute') {
    setImageUrl(post.image_url);
  } else {
    setImageUrl(post.image_url);
  }
  setOpenCoa(true);
}

// add an effect (fetch) here for the posts/all endpoint defined in api then a response 
  useEffect(() => {
    fetch(BASE_URL + 'post/all')
    // after fetching log response to console and if response ok return the posts if not throw error
      .then(response => {
        // console.log(response.json());
        const json = response.json()
        console.log(json);
        if (response.ok) {
          return json
        }
        throw response
      })
      // sort the post in reverse order - convert timestamp into date element then ise that to sort
      // timestamps split into 5 parts (month is second split and requires a -1 due to how months are handled in react)
      .then (data => {
        const result = data.sort((a,b) => {
          const t_a = a.timestamp.split(/[-T:]/);
          const t_b = b.timestamp.split(/[-T:]/);
          const d_a = new Date(Date.UTC(t_a[0], t_a[1]-1, t_a[2], t_a[3], t_a[4], t_a[5]));
          const d_b = new Date(Date.UTC(t_b[0], t_b[1]-1, t_b[2], t_b[3], t_b[4], t_b[5]));
          return d_b - d_a
        })
        return result
      })
      // get the data to set posts
      .then (data => {
        setPosts(data)
      })
      // catch any errors and log to the console
      .catch(error => {
        console.log(error);
        //  for dev purposes show an alert for any errors
        // alert(error)
      })
  }, [])


// populate the app with content
// define a div that wraps the entire app (.app)
// add a modal to display login - when openSignIn is true then modal is open false/closed
// button click below sets openSignIn to true and close sets it to false
// define a div for the app header this holds login/out/signup and logo
// define a div that wraps all posts - inside map all posts and display each in a <post> tag

// authorization at signin (login) - prevent default functionality 
// fetch the login endpoint from api
// pass formData.append items to the fetch statement using the requestOptions function
  const signIn = (event) => {
    event?.preventDefault();

    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    const requestOptions = {
      method: 'POST',
      body: formData
    }

    fetch(BASE_URL + 'login', requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then(data => {
        console.log(data);
        console.log(data.cpnyname);
        setAuthToken(data.access_token)
        setAuthTokenType(data.token_type)
        setUserId(data.user_id)
        setUsername(data.username)
        window.localStorage.setItem('usertype', data.usertype)
        window.sessionStorage.setItem('likerCompany', data.cpnyname)
        window.sessionStorage.setItem('likerEmail', data.email)
        window.sessionStorage.setItem('likerPhone', data.phonenum)
        window.sessionStorage.setItem('likerFirstname', data.firstname)
        window.sessionStorage.setItem('likerLastname', data.lastname)        
        // console.log(liker_phonenum);
      })
      .catch(error => {
        console.log(error);
        alert(error)
      })
    
    setOpenSignIn(false);
  }
// Remove / reset userdata on click of the logout button
  const signOut = (event) => {
    setAuthToken(null)
        setAuthTokenType(null)
        setUserId('')
        setUsername('')
        setUsertype('')
  }

  // Sign up function fetching /user/ endpoint from api
  const signUp = (event) => {
    event?.preventDefault();

    const json_string = JSON.stringify({
      username: username,
      email: email,
      password: password,
      firstname: firstname,
      lastname: lastname,
      phonenum: phonenum,
      cpnyname: cpnyname,
      website: website,
      usertype: usertype
    })
    const requestOption = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: json_string
    }

    fetch(BASE_URL + 'user/', requestOption)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      throw response      
      })
      .then(data => {
        console.log(data);        
        // invoke the signIn function to log users in once signup is successfully completed
        signIn();
        
      })
      .catch(error => {
        console.log(error);
        alert(error);
      })

    setOpenSignUp(false)
  }

  const avatarStyle = {
    backgroundColor: "#fff",
    border: "4px solid #634A00",
  boxShadow: 2
  };

  const switchTheme = () => {
    const newTheme = themeld === 'light' ? 'dark' : 'light';
    setThemeld(newTheme);
  }

  // establish a common theme
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // backgroundColor: 'transparent',
            borderColor: '#634A00', // change the outline color to match the background color
            color: '#634A00',
            '&:hover': {
              // backgroundColor: '#388e3c', // change the background color on hover
              borderColor: '#383416', // change the outline color on hover
            },
          },
          outlined: {
            borderColor: '#634A00', // change the outline color for the outlined variant
          },
        },
      },
    },
  });
  // const theme = createTheme({
  //   primary: {
  //     // light: will be calculated from palette.primary.main,
  //     main: '#383416',
  //     // dark: will be calculated from palette.primary.main,
  //     // contrastText: will be calculated to contrast with palette.primary.main
  //   },
  //   secondary: {
  //     light: '#383416',
  //     main: '#383416',
  //     // dark: will be calculated from palette.secondary.main,
  //     contrastText: '#ffcc00',
  //   },
  //   // Provide every color token (light, main, dark, and contrastText) when using
  //   // custom colors for props in Material UI's components.
  //   // Then you will be able to use it like this: `<Button color="custom">`
  //   // (For TypeScript, you need to add module augmentation for the `custom` value)
  //   custom: {
  //     light: '#383416',
  //     main: '#383416',
  //     dark: '#ef6c00',
  //     contrastText: 'rgba(0, 0, 0, 0.87)',
  //   },
  //   // Used by `getContrastText()` to maximize the contrast between
  //   // the background and the text.
  //   contrastThreshold: 3,
  //   // Used by the functions below to shift a color's luminance by approximately
  //   // two indexes within its tonal palette.
  //   // E.g., shift from Red 500 to Red 300 or Red 700.
  //   tonalOffset: 0.2,

  //   components: {
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           backgroundColor: '#4caf50 !important',
  //         },
  //       },
  //     },
  //   },
  // });
  
  const usrtyp = window.localStorage.getItem('usertype')
  

  // NEW FETCH LIKES STATEMENT USING USEEFFECT
  
  const fetchLikes = () => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: authTokenType + " " + authToken,
        "Content-Type": "application/json",
      }),
    };
      
      fetch(BASE_URL + "like/all/", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setLikes(data);
        console.log(data);
        // console.log(likes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFetchLikesClick = () => {
    fetchLikes();
  };
  
  useEffect(() => {
    fetchLikes();
  }, [like]);
  
  // get all the wants 
  const fetchWants = () => {
    const requestOptions = {
      method: 'GET',
      headers: new Headers({
        'Authorization': authTokenType + ' ' + authToken,
        'Content-Type': 'application/json'
      }),
    };

    fetch(BASE_URL + 'want/all/', requestOptions)
        .then(response => {
            if(response.ok) {
                return response.json()
            }
            throw response
        })
        .then (data => {
            setWants(data)
            console.log(data);
        })
        .catch(error => {
            console.log(error);
        })
  };

  const handleFetchWantsClick = () => {
    fetchWants();
  };
  
  
  useEffect(() => {
    fetchWants();
  }, [like]);

  // handling the change in state of the switches in the wantlist
  const handleActiveChange = (event) => {
    const { id, checked } = event.target;
        
    setActiveStates((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    const wantStatus = checked ? 'active' : 'new';
    updateUserItems(id, wantStatus); // Call updateUserItems instead of updateWantStatus
    
  };

  function updateUserItems(id, wantStatus) {
    console.log(itemIdsByUser);
    // Convert id to a number
    const userId = Number(id);
    // Filter itemIdsByUser to find the object with a matching user_id
    const userItems = itemIdsByUser.find(user => user.user_id === userId);
    if (!userItems) {
      console.error(`No user found with ID ${id}`);
      return;
  }
    console.log(userItems);
    if (userItems) {
      // Loop through the wantIds array and call updateWantStatus for each item
      userItems.wantIds.forEach(wantId => {
        updateWantStatus(wantId, wantStatus);
        console.log(wantId);
    console.log(wantStatus);
      });      
    }
  }

  function updateWantStatus(wantId, wantStatus) {
    // const url = `BASE_URL + 'want/update_status/${wantId}`;
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ wantStatus: wantStatus })
    };
    fetch(BASE_URL + 'want/update_status/' + `${wantId}`, options)
      .then(response => {
        if (response.ok) {
          console.log(`Want ${wantId} status updated to ${wantStatus}`);
        } else {
          console.error(`Failed to update want ${wantId} status`);
        }
      })
      .catch(error => {
        console.error(`Error updating want ${wantId} status: ${error}`);
      });
  }

  // Experimental code to sort wants by user id (broker) then group post company name (producer)
  // First, you need to sort the wants array by user_id. You can use the Array.sort() method for this. You pass in a function that takes two objects and compares their user_id property. If the user_id of the first object is less than the second object, it returns a negative number. If it's greater, it returns a positive number. If they are equal, it returns 0. This will sort the array in ascending order by user_id.
  // Once the wants array is sorted by user_id, you can group the objects by user_id. To do this, you can use the Array.reduce() method. This method takes a function and an initial value. The function takes two parameters: the accumulator and the current value. In this case, the accumulator is an object that has keys equal to the unique user_id values in the array. The initial value for the accumulator is an empty object {}. In the function, you check if the current user_id is already a key in the accumulator. If it is, you add the current object to the array of objects for that user_id. If it's not, you create a new key in the accumulator with the user_id as the key and an array with the current object as the value.
  // Finally, you can group the objects by post_cpnyname for each user_id. You can do this inside the reduce() function by creating a nested object for each user_id key. The nested object will have keys equal to the unique post_cpnyname values for that user_id. The values for each post_cpnyname key will be an array of objects that have that post_cpnyname value.
  // This code will produce an object with keys equal to the unique user_id values in the wants state. Each key will have a value that is an object with keys equal to the unique post_cpnyname values for that user_id. The values for each post_cpnyname key will be an array of objects that have that post_cpnyname value.
  // const sortedWants = wants.sort((a, b) => a.user_id - b.user_id);
  
  const groupedByUserId = wants
  .filter(want => want.wantStatus === 'new')
  .reduce((acc, cur) => {
    const { 
      id,
      user_id,
    user_firstname,
    user_lastname,
    user_cpnyname,
    user_email,
    user_phonenum,
    post_cpnyname,
    post_id,
    post_email,
    post_firstname,
    post_lastname,
    post_phonenum,
    post_prod_name,
    post_thc_lvl,
    post_cbd_lvl,
    post_terp_lvl,
    post_vol_num,
    post_vol_unit,
    wantStatus
     } = cur;
    const existingUserIndex = acc.findIndex(obj => obj.user_id === user_id);
    
    if (existingUserIndex >= 0) {
      const existingPostIndex = acc[existingUserIndex].posts.findIndex(post => post.post_cpnyname === post_cpnyname);
      
      if (existingPostIndex >= 0) {
        acc[existingUserIndex].posts[existingPostIndex].items.push({
          id,
          username: cur.username,
          user_email,
          user_firstname,
          user_lastname,
          user_cpnyname,
          user_phonenum,
          post_id,
          post_cpnyname,
          post_email,
          post_firstname,
          post_lastname,
          post_phonenum,
          post_prod_name,
          post_thc_lvl,
          post_cbd_lvl,
          post_terp_lvl,
          post_vol_num,
          post_vol_unit,
          wantStatus
        });
      } else {
        acc[existingUserIndex].posts.push({
          post_cpnyname,
          post_firstname,
          post_lastname,
          post_email,
          post_phonenum,
          items: [{
            id,
            username: cur.username,
            user_email,
            user_firstname,
            user_lastname,
            user_cpnyname,
            user_phonenum,
            post_id,
            post_cpnyname,
            post_email,
            post_firstname,
            post_lastname,
            post_phonenum,
            post_prod_name,
            post_thc_lvl,
            post_cbd_lvl,
            post_terp_lvl,
            post_vol_num,
            post_vol_unit,
            wantStatus
          }],
        });
      }
    } else {
      acc.push({
        user_id,
        user_firstname,
        user_lastname,
        user_cpnyname,
        user_email,
        user_phonenum,
        posts: [{
          post_cpnyname,
          post_firstname,
          post_lastname,
          post_email,
          post_phonenum,          
          items: [{
            id,
            username: cur.username,
            user_email,
            user_firstname,
            user_lastname,
            user_cpnyname,
            user_phonenum,
            post_id,
            post_cpnyname,
            post_email,
            post_firstname,
            post_lastname,
            post_phonenum,
            post_prod_name,
            post_thc_lvl,
            post_cbd_lvl,
            post_terp_lvl,
            post_vol_num,
            wantStatus
          }],
        }],
      });
    }
    
    return acc;
  }, []).sort((a, b) => a.user_id - b.user_id);


// console.log(groupedByUserId);

// Map the want ids of each group of posts (grouped by user_id) into a new array
function mapItemIdsByUser(groupedByUserId) {
  return groupedByUserId.map(user => {
    const wantIds = user.posts.flatMap(post => post.items.map(item => item.id));
    return { user_id: user.user_id, wantIds };
  });
}

const itemIdsByUser = mapItemIdsByUser(groupedByUserId);
// console.log(itemIdsByUser);

  // Experimental code ends

  

  const deleteLikes = (index) => {
    var post_like_id = likes[index].post_id + '_like'
    var post_like_value = localStorage.getItem(likes[index].post_id + '_like');
    // console.log(post_like_id);
    console.log(post_like_value);

  const requestOptions = {
      method: 'GET',
      headers: new Headers({
          'Authorization': authTokenType + ' ' + authToken
      })
  }

  fetch(BASE_URL + 'like/delete/' + post_like_value, requestOptions)
      .then (response => {
          if (response.ok) {
            console.log('delete a like');
            window.localStorage.setItem(likes[index].post_id + 'liked', 'false');
            window.localStorage.removeItem(post_like_id)
            fetchLikes();
            // console.log(likes[index].post_id + 'liked');
            // window.location.reload()                
          }
          throw response
      })
      // .then(data => {
      //     // setLike(false);
      //   })
      .check(error => {
          console.log(error);
      })

      
}

// sndWant goes here  sendLikes
const sndWant = (event) => {
  event?.preventDefault();
  // Get the likes state
  // const wants = likes;

  // Map each like object to a JSON string
  const jsonLikes = likes.map((like, index) => {
    return JSON.stringify({
      'username': like.username,
      'user_id': like.user_id,
      'post_id': like.post_id,
      'user_cpnyname': like.user_cpnyname,
      'user_email': like.user_email,
      'user_firstname': like.user_firstname,
      'user_lastname': like.user_lastname,
      'user_phonenum': like.user_phonenum,
      'post_cpnyname': like.post_cpnyname,
      'post_email': like.post_email,
      'post_firstname': like.post_firstname,
      'post_lastname': like.post_lastname,
      'post_phonenum': like.post_phonenum,
      'post_prod_name': like.post_prod_name,
      'post_thc_lvl': like.post_thc_lvl,
      'post_cbd_lvl': like.post_cbd_lvl,
      'post_terp_lvl': like.post_terp_lvl,
      'post_vol_num': like.post_vol_num,
      'post_vol_unit': like.post_vol_unit,
      'wantStatus': 'new'
    });
  });

  // Post each JSON string to the endpoint
  jsonLikes.forEach((jsonLike) => {
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Authorization': authTokenType + ' ' + authToken,
        'Content-Type': 'application/json'
      }),
      body: jsonLike
    };

    fetch(BASE_URL + 'want', requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.log(error);
      });
  });
};

  return (
    <ThemeProvider theme={theme}>
    <React.Fragment>
      <CssBaseline enableColorScheme />
    <div className='app' data-theme={themeld}>
      
      {/* COA MODAL */}
      <Modal
        open={openCoa}
        onClose={() => setOpenCoa(false)}>
          <div style={modalStyle} className='paper'>            
            <img 
              className="post_image"
              src={imageUrl}
              alt="coa" 
            />
          </div>
      </Modal>
      {/* SIGNIN MODAL */}
      <Modal
        open={openSignIn}
        onClose={() => setOpenSignIn(false)}>
          <div style={modalStyle} className='paper'>
            <form className='app_signin'>
              <center>
              <div className='siteId'>
                <span>&nbsp;</span><span className='siteTitle'>signIn</span>
              </div>
              </center>
              <Input
                placeholder='username'
                type='text'
                value={username}
                onChange={(e) => setUsername(e.target.value)} />
              <Input 
                placeholder='password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)} />
              <Button
                type='Submit'
                variant="outlined" 
                className='imageupload_button'
                onClick={signIn}>Login</Button>
            </form>
          </div>
      </Modal>
      {/* LIKELIST MODAL */}
      <Modal 
        open={openLikeList}
        // adding a window reload on close of this modal so that like buttons state gets reset in the event that user removes a liked item from likelistV1
        // Using window.location.reload below is not ideal - find a way to refresh a specific post id or just the like state
        // onClose={() => {setOpenLikeList(false); window.location.reload() }}>
        onClose={() => {setOpenLikeList(false);}}>
        <div style={modalStyle} >
          
          <div className='app_likesv1'>  
            <div className='likeListV1Header'>
              <p>
                Let us know which products you are interested in
              </p>
            </div>
            {
              likes.map((like, index) => (
                
                <div key={like.post_id} className='likeListv1Box'>
                  <div className='likeListv1BoxA'>
                    <Avatar sx={avatarStyle} >P</Avatar>
                  </div>
                  <div className='likeListv1BoxB'>
                    <div className='likeListv1BoxC'>
                      <strong>{like.post_prod_name} : </strong> &nbsp; <strong>{like.post_vol_num} </strong><strong>{like.post_vol_unit}</strong>
                    </div>
                    <div className='likeListv1BoxD'>
                      <div className='likev1thc'>
                        <strong>{like.post_thc_lvl}% THC</strong>
                      </div>
                      <div className='likev1cbd'>
                        <strong>{like.post_cbd_lvl}% CBD</strong>
                      </div>
                      <div className='likev1trp'>
                        <strong>{like.post_terp_lvl}% TERPS</strong>
                      </div>
                    </div>
                  </div>
                  <div className='delButV1'>
                    {authToken && (
                      <Button className="post_delete" onClick={()=> {deleteLikes(index);}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 1216 1312"><path fill="#fafafa" d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68z"/></svg>
                      </Button>
                    )}
                  </div>
                </div>    
              ))
            }            
          </div>
          <div className='sendWants'>
            <Button className="send wantlist" onClick={()=> sndWant()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path fill="#fafafa" d="M20.56 3.34a1 1 0 0 0-1-.08l-17 8a1 1 0 0 0-.57.92a1 1 0 0 0 .6.9L8 15.45v6.72L13.84 18l4.76 2.08a.93.93 0 0 0 .4.09a1 1 0 0 0 .52-.15a1 1 0 0 0 .48-.79l1-15a1 1 0 0 0-.44-.89ZM18.1 17.68l-5.27-2.31L16 9.17l-7.65 4.25l-2.93-1.29l13.47-6.34Z"/></svg>
            </Button>
          </div>
        </div>
      </Modal>
      {/* WANT DIALOG */}      
      <Dialog 
        open={openLikeListV2}
        // adding a window reload on close of this modal so that like buttons state gets reset in the event that user removes a liked item from likelistV1
        // Using window.location.reload below is not ideal - find a way to refresh a specific post id or just the like state
        onClose={() => {setOpenLikeListV2(false);}}
        scroll="paper"
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            height: '98%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        >
          {/* <DialogTitle>LiquidTHC : Wantlist</DialogTitle> */}
          <DialogContent dividers>
          <Typography>

        <div  >
          
          <div className='app_likesv2'>  
            {/* <div className='likeListV2Header'>
              <p>
                Interest has been shown in the following:
              </p>
            </div> */}
            
            <div className='wantlist'>
            {groupedByUserId.map(user => ( 
              <div key={user.user_id} className={`userWantsWrap ${user.user_id} ${activeStates[`${user.user_id}`] ? 'active' : ''}`}>
                {/* {mapItemIdsByUser(groupedByUserId).find(item => item.userId === user.user_id).itemIds.join(", ")} */}
                <div className='wanterDetails'>
                {/* <h2>User ID: {user.user_id}</h2> */}
                  <div className='wdAvatar'>
                    <Avatar sx={avatarStyle}  variant="rounded">B</Avatar>
                  </div>
                  <div className='wdName'>
                    <h3>{user.user_firstname} {user.user_lastname} from {user.user_cpnyname}</h3>
                    <h4>{user.user_phonenum} | {user.user_email}</h4>
                  </div>
                </div>
                <p>Is interested in the following products from:</p>
                {user.posts.map(post => (
                  <div key={post.post_cpnyname} className='wantsProductWrap'>
                    <div className='wpNameWrap'>
                      <div className='wpAvatar'>
                      <Avatar sx={avatarStyle}>P</Avatar>
                      </div>
                      <div className='wpName'>
                        <h3>{post.post_cpnyname} | {post.post_firstname} {post.post_lastname}</h3>
                        <h4>{post.post_phonenum} | {post.post_email}</h4>
                      </div>
                    </div>
                    <div className='wpListWrap'>
                      <ul>
                        {post.items.map(item => (
                          <li key={item.post_id} id={item.id}>
                            <p>{item.post_prod_name}: {item.post_vol_num} {item.post_vol_unit}</p>                  
                            {/* Add more fields as needed */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
                <div className='inProgressWrap'>
                  <div className='switchBox'>
                    <p>Accept</p>
                    <Switch
                    id={`${user.user_id}`}
                    checked={activeStates[user.user_id] || false}
                    onChange={handleActiveChange}
                    />
                  </div>
                </div>
              </div> // end userWantsWrap
            ))}
            </div>
          </div>
        </div>
        </Typography>
        </DialogContent>
      </Dialog>
      {/* IMAGE UPLOAD MODAL */}
      <Modal 
        open={openNewPost}
        onClose={() => setOpenNewPost(false)}>
        <div style={modalStyle} > 
      {
        authToken ? (
          
            <ImageUpload
              authToken={authToken}
              authTokenType={authTokenType}
              userId={userId}
            />
          
          
        ) : (
          <h3>You need to be logged in to upload</h3>
        )
      }
        </div>
      </Modal>
      {/* SIGNUP MODAL */}
      <Modal
        open={openSignUp}
        onClose={() => setOpenSignUp(false)}>
          <div style={modalStyle} className='paper'>
            <form className='app_signin'>
              <div className='siteId'>
                <span>&nbsp;</span><span className='siteTitle'>signUp</span>
              </div>
              
              <FormControl className='fcntrl'>
                <FormLabel id="radio-group-label">Select Your User Type</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-group-label"
                  // defaultValue="producer"
                  name="radio-buttons-group"
                  onChange={(e) => setUsertype(e.target.value)}
                  row
                >
                  <FormControlLabel value="producer" control={<Radio />} label="Producer" />
                  <FormControlLabel value="broker" control={<Radio />} label="Broker" />
                </RadioGroup>
              </FormControl>
              <Input
                placeholder='username'
                type='text'
                value={username}
                onChange={(e) => setUsername(e.target.value)} />
              <Input 
                placeholder='email'
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)} />
              <Input 
                placeholder='first name'
                type='text'
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)} />
              <Input 
                placeholder='last name'
                type='text'
                value={lastname}
                onChange={(e) => setLastname(e.target.value)} />
                <Input 
                placeholder='phone number'
                type='text'
                value={phonenum}
                onChange={(e) => setPhonenum(e.target.value)} />
                
              
              <Input 
                placeholder='Company name'
                type='text'
                value={cpnyname}
                onChange={(e) => setCpnyname(e.target.value)} />
              <Input 
                placeholder='Company website'
                type='text'
                value={website}
                onChange={(e) => setWebsite(e.target.value)} />
              <Input 
                placeholder='password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)} />
              <Button
                type='Submit'
                variant="outlined" 
                className='imageupload_button'
                onClick={signUp}>Sign up</Button>
            </form>
          </div>

      </Modal>



      <div className='app_header'>
        <div className='leftBox'>
          <div className='siteId'>
            <span>&nbsp;</span><span className='siteTitle'>liquidthc</span>
          </div>
          <div className='thmSwitch'>
            <IconButton onClick={switchTheme}>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 32 32"><path fill="#fafafa" d="M16 4C9.383 4 4 9.383 4 16s5.383 12 12 12s12-5.383 12-12S22.617 4 16 4zm0 2v20c-5.535 0-10-4.465-10-10S10.465 6 16 6z"/></svg>
            </IconButton>
          </div>
          
          <div className='sndBtn'>
          {authToken && usrtyp == 'broker' && ( 
            <IconButton aria-label="send likes button" className="send_likes" onClick={() => {setOpenLikeList(true); handleFetchLikesClick()}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24"><path fill="#fafafa" d="M20.56 3.34a1 1 0 0 0-1-.08l-17 8a1 1 0 0 0-.57.92a1 1 0 0 0 .6.9L8 15.45v6.72L13.84 18l4.76 2.08a.93.93 0 0 0 .4.09a1 1 0 0 0 .52-.15a1 1 0 0 0 .48-.79l1-15a1 1 0 0 0-.44-.89ZM18.1 17.68l-5.27-2.31L16 9.17l-7.65 4.25l-2.93-1.29l13.47-6.34Z"/></svg>
            </IconButton>
            )}
          {authToken && usrtyp == 'producer' && (
            <IconButton aria-label="add new post button" className="send_likes" onClick={() => setOpenNewPost(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24"><path fill="currentColor" d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8z"/></svg>
            </IconButton>
            )}
          {authToken && usrtyp == 'dog' && (
            <IconButton aria-label="view want list" className="send_likes" onClick={() => {setOpenLikeListV2(true); handleFetchWantsClick()}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24"><path fill="currentColor" d="M4 20v-6a8 8 0 1 1 16 0v6h1v2H3v-2h1zm2 0h12v-6a6 6 0 1 0-12 0v6zm5-18h2v3h-2V2zm8.778 2.808l1.414 1.414l-2.12 2.121l-1.415-1.414l2.121-2.121zM2.808 6.222l1.414-1.414l2.121 2.12L4.93 8.344L2.808 6.222zM7 14a5 5 0 0 1 5-5v2a3 3 0 0 0-3 3H7z"/></svg>
            </IconButton>
            )}
          </div>
          
        </div>
        
        {authToken ? (
          <IconButton className='signOut' onClick={() => signOut()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24"><path fill="currentColor" d="M6 8c0-2.21 1.79-4 4-4s4 1.79 4 4s-1.79 4-4 4s-4-1.79-4-4m6 10.2c0-.96.5-1.86 1.2-2.46V14.5c0-.05.02-.11.02-.16c-.99-.22-2.07-.34-3.22-.34c-4.42 0-8 1.79-8 4v2h10v-1.8m10 .1v3.5c0 .6-.6 1.2-1.3 1.2h-5.5c-.6 0-1.2-.6-1.2-1.3v-3.5c0-.6.6-1.2 1.2-1.2v-2.5c0-1.4 1.4-2.5 2.8-2.5s2.8 1.1 2.8 2.5v.5h-1.3v-.5c0-.8-.7-1.3-1.5-1.3s-1.5.5-1.5 1.3V17h4.3c.6 0 1.2.6 1.2 1.3Z"/></svg>
          </IconButton>
        ) : (
        <div className='signInUp'>
          <IconButton onClick={() => setOpenSignIn(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24"><path fill="#fafafa" d="M18 11c.7 0 1.37.1 2 .29V10c0-1.1-.9-2-2-2h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h6.26A6.995 6.995 0 0 1 18 11zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6z"/><path fill="#fafafa" d="M18 13c-2.76 0-5 2.24-5 5s2.24 5 5 5s5-2.24 5-5s-2.24-5-5-5zm0 2c.83 0 1.5.67 1.5 1.5S18.83 18 18 18s-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5zm0 6c-1.03 0-1.94-.52-2.48-1.32c.73-.42 1.57-.68 2.48-.68s1.75.26 2.48.68c-.54.8-1.45 1.32-2.48 1.32z"/></svg>
          
          </IconButton>
          <IconButton onClick={() => setOpenSignUp(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24"><path fill="#fafafa" d="M15 14c-2.67 0-8 1.33-8 4v2h16v-2c0-2.67-5.33-4-8-4m-9-4V7H4v3H1v2h3v3h2v-3h3v-2m6 2a4 4 0 0 0 4-4a4 4 0 0 0-4-4a4 4 0 0 0-4 4a4 4 0 0 0 4 4Z"/></svg>
          </IconButton>
        </div>
          )
        }
      </div>
      
      {authToken ? (
          <div className='app_posts'>  
          
          {
          posts.map(post => (
            <Post
              post = {post}
              authToken={authToken}
              authTokenType={authTokenType}
              username={username}
              openCoaModal={openCoaModal}
            />
          ))
          }
        </div>) : (
        <div id='homeWrap'>
          <div id='heroStatement'>          
            <div className='introLink'>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path fill="currentColor" d="M503.47 360.25c-1.56-.82-32.39-16.89-76.78-25.81c64.25-75.12 84.05-161.67 84.93-165.64c1.18-5.33-.44-10.9-4.3-14.77c-3.03-3.04-7.12-4.7-11.32-4.7c-1.14 0-2.29.12-3.44.38c-3.88.85-86.54 19.59-160.58 79.76c.01-1.46.01-2.93.01-4.4c0-118.79-59.98-213.72-62.53-217.7A15.973 15.973 0 0 0 256 0c-5.45 0-10.53 2.78-13.47 7.37c-2.55 3.98-62.53 98.91-62.53 217.7c0 1.47.01 2.94.01 4.4c-74.03-60.16-156.69-78.9-160.58-79.76c-1.14-.25-2.29-.38-3.44-.38c-4.2 0-8.29 1.66-11.32 4.7A15.986 15.986 0 0 0 .38 168.8c.88 3.97 20.68 90.52 84.93 165.64c-44.39 8.92-75.21 24.99-76.78 25.81a16.003 16.003 0 0 0-.02 28.29c2.45 1.29 60.76 31.72 133.49 31.72c6.14 0 11.96-.1 17.5-.31c-11.37 22.23-16.52 38.31-16.81 39.22c-1.8 5.68-.29 11.89 3.91 16.11a16.019 16.019 0 0 0 16.1 3.99c1.83-.57 37.72-11.99 77.3-39.29V504c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-64.01c39.58 27.3 75.47 38.71 77.3 39.29a16.019 16.019 0 0 0 16.1-3.99c4.2-4.22 5.71-10.43 3.91-16.11c-.29-.91-5.45-16.99-16.81-39.22c5.54.21 11.37.31 17.5.31c72.72 0 131.04-30.43 133.49-31.72c5.24-2.78 8.52-8.22 8.51-14.15c-.01-5.94-3.29-11.39-8.53-14.15z"/></svg>
              &nbsp; Introducing liquidTHC
            </div>
            <div className='introHead'><h1>An Anonymous <br/><span>Cannabis</span><br/> Marketplace</h1></div>
            <div className='introTxt'>liquidTHC is a private marketplace for licensed producers and cannabis brokers to connect</div>
          </div>
          <div id='heroCTA'>
            <Button
                variant="outlined" 
                className='imageupload_button'
                onClick={() => setOpenSignUp(true)}>Get Started For Free</Button>
          </div>
          <div id='lookStatement'>
            <div className='lookHead'><h2>Get a good look</h2></div>
            <div className='lookTxt'>See what producers have to offer by browsing our real-time feed. Each post has a swipe gallery and a link to the products COA.</div>
          </div>
          <div id='lookCards'>
            <div class="main">
              <div class="container" id="container1">
                <div class="card cardA">
                  {/* <a href="https://www.google.com" target="_blank"></a> */}
                </div>
              </div>
              <div class="container" id="container2">
                <div class="card cardB">
                  {/* <a href="https://www.google.com" target="_blank"></a> */}
                </div>
              </div>
              <div class="container" id="container3">
                <div class="card cardC">
                  {/* <a href="https://www.google.com" target="_blank"></a> */}
                </div>
              </div>
              <div class="container" id="container4">
                <div class="card cardD">
                  {/* <a href="https://www.google.com" target="_blank"></a> */}
                </div>
              </div>
              <div class="container" id="container5">
                <div class="card cardE">
                  {/* <a href="https://www.google.com" target="_blank"></a> */}
                </div>
              </div>
            </div>
          </div>
          <div id='powerStatement'>
            <div className='powerHead'><h2>Powerful in<br/> its <span>simplicity</span></h2></div>
            <div className='powerTxt'>As a producer you let us know about the product you have available. As a broker, you let us know what products you are interested in.<br/> <span>It only takes a click or two</span></div>
          </div>
          
        </div>
          )
        }
      
      
    </div>
    </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
