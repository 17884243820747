import React, {useRef, useState, useEffect} from 'react';
import './Post.css'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

// define base url
// const BASE_URL = 'http://localhost:8000/'
const BASE_URL = 'https://api.cbdpower.ca/' 

// define a function Post - pass the parameter 'post and return content 
// userId, liker_cpnyname, liker_email, liker_firstname, liker_lastname, liker_phonenum
function Post({ post, authToken, authTokenType, username, openCoaModal}){
        
    // define the image url paths for absolute and relative images
    const [imagesUrls, setImagesUrls] = useState([])
    // const [imageUrl, setImageUrl] = useState('')
    // define comments and setComments as an empty array
    const [comments, setComments] = useState([])
    const [newComment, setNewComment] = useState('')
    const [like, setLike] = useState(false)
    
    

    useEffect(() => {
        setLike(JSON.parse(window.localStorage.getItem(post.id + 'liked')));
      }, []);
    
      useEffect(() => {
        window.localStorage.setItem(post.id + 'liked', like);
      }, [like]);

    // if image url type is absolute use the url given - (else) if relative then prepend BASE_URL to post.image_url 
    // useEffect(() => {
    //     if (post.image_url_type == 'absolute') {
    //         setImageUrl(post.image_url)
    //     } else {
    //         setImageUrl(BASE_URL + post.image_url)            
    //     }
    // }, [post]);

    useEffect(() => {

        if (!post.id) {
            return;
        }

        fetch(BASE_URL + 'post/gallery/' + post.id)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw response;
            })
            .then(data => {
                const urls = data.map(image => image.url);
                setImagesUrls(urls);
                console.log(data);
                console.log(urls);
            })
            .catch(error => {
                console.log(error);
            });
    }, [post]);


    function addLike() {
        console.log('add a like');
        sndLike ();
    }

// Like function fetching /like/ endpoint from api
  const sndLike = (event) => {
    event?.preventDefault();

    const json_string = JSON.stringify({      
        'username': username,
        'user_id': JSON.parse(localStorage.getItem('userId')),
        'post_id': post.id,
        'user_cpnyname': sessionStorage.getItem('likerCompany'),
        'user_email': sessionStorage.getItem('likerEmail'),    
        'user_firstname': sessionStorage.getItem('likerFirstname'),
        'user_lastname': sessionStorage.getItem('likerLastname'),
        'user_phonenum': sessionStorage.getItem('likerPhone'),
        // timestamp = datetime.now(),
        'post_cpnyname': post.creator_cpnyname,
        'post_email': post.creator_email,   
        'post_firstname': post.creator_firstname,
        'post_lastname': post.creator_lastname,
        'post_phonenum': post.creator_phonenum,
        'post_prod_name': post.product_name,
        'post_thc_lvl': post.thc_lvl,
        'post_cbd_lvl': post.cbd_lvl,
        'post_terp_lvl': post.terp_lvl,
        'post_vol_num': post.vol_num,
        'post_vol_unit': post.vol_unit      
    })
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Authorization': authTokenType + ' ' + authToken,
        'Content-Type': 'application/json'
    }),
      body: json_string
    }

    fetch(BASE_URL + 'like', requestOptions)
      .then(response => {
        if(response.ok) {
          return response.json()          
        }
        throw response      
      })
      .then(data => {
        console.log(data);
        // console.log(data.id);
        localStorage.setItem(data.post_id + '_like', data.id);        
      })
      .catch(error => {
        console.log(error);
      })
    
}

// const storeLike = (event) => {
//     window.localStorage.setItem(post.id + 'liked', like);
    
// }


const deleteLike = (event) => {
    event?.preventDefault();
    var post_like_id = post.id + '_like'
    var post_like_value = localStorage.getItem(post.id + '_like');
    // console.log(post_like_id);
    console.log(post_like_value);

    const requestOptions = {
        method: 'GET',
        headers: new Headers({
            'Authorization': authTokenType + ' ' + authToken
        })
    }

    fetch(BASE_URL + 'like/delete/' + post_like_value, requestOptions)
        .then (response => {
            if (response.ok) {
                // window.location.reload()  
                console.log('delete a like');  
                window.localStorage.removeItem(post_like_id)            
            }
            throw response
        })
        // .then(data => {
        //     // setLike(false);
        //   })
        .check(error => {
            console.log(error);
        })
}

    // pass the post comments from the api to the setComments constant above
    useEffect(() => {
        setComments(post.comments)
    },[])

    const handleDelete = (event) => {
        event?.preventDefault();

        const requestOptions = {
            method: 'GET',
            headers: new Headers({
                'Authorization': authTokenType + ' ' + authToken
            })
        }

        fetch(BASE_URL + 'post/delete/' + post.id, requestOptions)
            .then(response => {
                if (response.ok) {
                    window.location.reload()
                }
                throw response
            })
            .check(error => {
                console.log(error);
                // alert(error);
            })
    }

    const postComment = (event) => {
        event?.preventDefault()

        const json_string = JSON.stringify({
            'username' : username,
            'text': newComment,
            'post_id': post.id
        })

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': authTokenType + ' ' + authToken,
                'Content-Type': 'application/json'
            }),
            body: json_string
        }

        fetch(BASE_URL + 'comment', requestOptions)
            .then(response => {
                if(response.ok) {
                    return response.json()
                }
            })
            .then(data => {
                fetchComments()
            })
            .catch(error => {
                console.log(error);
            }) 
            .finally(() => {
                setNewComment('')
            })
    }

    const fetchComments = () => {
        fetch(BASE_URL + 'comment/all/' + post.id)
            .then(response => {
                if(response.ok) {
                    return response.json()
                }
                throw response
            })
            .then (data => {
                setComments(data)
            })
            .catch(error => {
                console.log(error);
            })
    }

    const avatarStyle = {
        backgroundColor: "var(--avitarBk)",
        color: "var(--avitarTxt)",
        border: "4px solid #634A00",
        boxShadow: 2
    };

    const swiperElRef = useRef(null);

    useEffect(() => {
      // listen for Swiper events using addEventListener
      swiperElRef.current.addEventListener('progress', (e) => {
        const [swiper, progress] = e.detail;
        console.log(progress);
      });
  
      swiperElRef.current.addEventListener('slidechange', (e) => {
        console.log('slide changed');
      });
    }, []);

    function renderImageSlides(imagesUrls) {
        return imagesUrls.map(image_Url => (
            <swiper-slide key={image_Url}>
                <img 
                    className="post_image"
                    src={image_Url}
                    alt={post.caption} 
                />
            </swiper-slide>
        ));
    }
    
    // Handle the click for opening the COA modal
function handleCoaClick() {
    openCoaModal();
  }

    return (
        
        <div className="post">

            <div className="post_header">
            <Avatar sx={avatarStyle} >P</Avatar>
                <div className="post_headerInfo">                    
                <h3>Smells like ... {post.caption}</h3>
                <Button className="post_delete" onClick={handleDelete}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 1216 1312"><path fill="#fafafa" d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68z"/></svg>
                </Button>
                </div>
            </div>
            <swiper-container            
                ref={swiperElRef}
                slides-per-view="auto"
                navigation="false"
                pagination="false"
            >

                {renderImageSlides(imagesUrls)}
                
        
            </swiper-container>
            
            
            {authToken && (
                <div className="likeButton">                    
                        
                            {like ? (
                                <IconButton aria-label="unlike button" className="post_like" onClick={() => {setLike((prevLike) => !prevLike); deleteLike()}}>
                                <SvgIcon><path fill="#634A00" d="m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53L12 21.35Z"/></SvgIcon>
                                </IconButton>
                            ): (
                                <IconButton aria-label="like button" className="post_like" onClick={() => {setLike((prevLike) => !prevLike); addLike()}}>
                                <SvgIcon><path fill="#634A00" d="m12.1 18.55l-.1.1l-.11-.1C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5c0 2.89-3.14 5.74-7.9 10.05M16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3Z"/></SvgIcon>
                                </IconButton>
                            )}
                        
                    
                </div>
            )}
            <h4 className="post_text">{post.product_name} | {post.vol_num}&nbsp;{post.vol_unit} available</h4>
            <div className='levels'>
                <div className='thcLvl'><div className='colorBlock'>thc %</div>{post.thc_lvl}</div>
                <div className='cbdLvl'><div className='colorBlock'>cbd %</div>{post.cbd_lvl}</div>
                <div className='terpLvl'><div className='colorBlock'>trp %</div>{post.terp_lvl}</div>
                <Button className='coaAvail' onClick={() => openCoaModal(post)}>COA</Button>
            </div>
            {/* <div className="post_comments">
                {
                    comments.map((comment) => (
                        <p>
                            <strong>{comment.username}:</strong> {comment.text}
                        </p>
                    ))
                }
            </div> */}

            {/* {authToken && (
                <form className="post_commentbox">
                    <input className="post_input"
                    type="text"
                    placeholder="Add a comment"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    />
                    <button
                        className="post_button"
                        type="submit"
                        disabled={!newComment}
                        onClick={postComment}>
                            Post
                        </button>
                </form>
            )} */}
        </div>
    )
}

// export default the function Post (as defined above)
export default Post