import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import { Widget } from "@uploadcare/react-widget";
import './ImageUpload.css'

// create a const that references the base url for the project
// const BASE_URL = 'http://localhost:8000/' 
const BASE_URL = 'https://api.cbdpower.ca/' 
// http://127.0.0.1:8000/   http://localhost:8000/
// , creator_cpnyname, creator_email, creator_firstname, creator_lastname, creator_phonenum
function ImageUpload({authToken, authTokenType, userId}) {
    const [caption, setCaption] = useState('');
    const [images, setImages] = useState([]);
    const [image, setImage] = useState(null);
    const [vol_num, setVol_num] = useState('');
    const [vol_unit, setVol_unit] = useState('');
    const [product_name, setProduct_name] = useState('');
    const [thc_lvl, setThc_lvl] = useState('');
    const [cbd_lvl, setCbd_lvl] = useState('');
    const [terp_lvl, setTerp_lvl] = useState('');
    const [creator_cpnyname, setCreator_cpnyname] = useState('');
    const [creator_email, setCreator_email] = useState('');
    const [creator_firstname, setCreator_firstname] = useState('');
    const [creator_lastname, setCreator_lastname] = useState('');
    const [creator_phonenum, setCreator_phonenum] = useState('');
    


    // const handleImagesChange = (e) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //       const uploadedImages = Array.from(e.target.files);
    //       setImages(uploadedImages);
    //     }
    //   }

    // const handleChange = (e) => {
    //     if (e.target.files[0]) {
    //         setImage(e.target.files[0])
    //     }
    // }

    // const handleUpload = (e) => {
    //     e?.preventDefault();

    //     const formData = new FormData();
    //         images.forEach((image) => {
    //             formData.append('images', image);
    //         });
    //         formData.append('image', image);
        
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: new Headers({
    //             'Authorization': authTokenType + ' ' + authToken
    //         }),
    //         body: formData
    //     }

    //     fetch(BASE_URL + 'post/image', requestOptions)
    //         .then(response => {
    //             if (response.ok) {
    //                 return response.json()
    //             }
    //             throw response
    //         })
    //         .then(data => {
                
    //             // create post here - calling const createPost from below
    //             createPost(data.filename, data.results)
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             alert(error);
    //         })
    //         .finally(() => {
    //             // reset the elements of the file upload form control
    //             setImages(null)
    //             setImage(null)
    //             setCaption('')
    //             document.getElementById('fileInput').value = null
    //             setVol_num('')
    //             setVol_unit('')
    //             setProduct_name('')
    //             setThc_lvl('')
    //             setCbd_lvl('')
    //             setTerp_lvl('')
        
    //         })

            
    // }
    
    useEffect(() => {
        fetch(BASE_URL + 'user/all/' + userId)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw response
            })
            .then (data => {
                // console.log(data);   
                         
                setCreator_cpnyname(data[0].cpnyname);
                setCreator_email(data[0].email);
                setCreator_firstname(data[0].firstname);
                setCreator_lastname(data[0].lastname);
                setCreator_phonenum(data[0].phonenum);

                // console.log(creator_email); 
                
                // window.localStorage.setItem('crtrPhone', creator_phonenum)
            })
            .catch(error => {
                console.log(error);                
                // alert(error);
            })
      }, [userId, creator_phonenum, creator_email]);
    
    
    const createPost = () => {

    const json_string = JSON.stringify({
        // 'image_urls': imagesUrls,
        'image_url': image,
        'image_url_type': 'relative',
        'caption': caption,
        'creator_id': userId,
        'creator_cpnyname': creator_cpnyname,
        'creator_email': creator_email,
        'creator_firstname': creator_firstname,
        'creator_lastname': creator_lastname,
        'creator_phonenum': creator_phonenum,
        'vol_num': vol_num, 
        'vol_unit': vol_unit, 
        'product_name': product_name, 
        'thc_lvl': thc_lvl, 
        'cbd_lvl': cbd_lvl, 
        'terp_lvl': terp_lvl,
        'image_urls': images
              
    })
    
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': authTokenType + ' ' + authToken,
                'Content-Type': 'application/json'
            }),
            body: json_string
        }

        fetch(BASE_URL + 'post', requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw response
            })
            .then(data => {
                window.location.reload()
                window.scrollTo(0,0)
                console.log(data);
            })
            .catch(error => {
                console.log(error);                
                // alert(error);
            })
            .finally(() => {
                // reset the elements of the file upload form control
                setImages(null)
                setImage(null)
                setCaption('')
                // document.getElementById('fileInput').value = null
                setVol_num('')
                setVol_unit('')
                setProduct_name('')
                setThc_lvl('')
                setCbd_lvl('')
                setTerp_lvl('')
        
            })
    }

    return (
        <div className='paper'>
            <form className='app_signin'>
                <center>
                <div className='siteId'>
                    <span>&nbsp;</span><span className='siteTitle'>productInfo</span>
                </div>
                </center>
                <div className='fiftyFifty'>
                    <div className='fiftyL'>
                        <div className='imgHead'>Product Images</div>
                        <Widget 
                            publicKey='f987671abfc6f3ecbba6' 
                            id='imagesInput' 
                            multiple='true'
                            multipleMax='5'
                            imagesOnly='true'
                            tabs='file camera gdrive gphotos dropbox instagram onedrive box'
                            imageShrink="800x600"
                            clearable='true'
                            debugUploads='true'
                            // onChange={console.log}
                            // onChange={handleImagesChange}
                            onFileSelect={async (group) => {
                                const files = await Promise.all(group.files());
                                const urls = files.map((file) => file.cdnUrl);
                                setImages (urls);
                            }}
                              
                        />
                    </div>
                    <div className='fiftyR'>
                        <div className='coaHead'>COA</div>
                        <Widget 
                            publicKey='f987671abfc6f3ecbba6' 
                            id='fileInput' 
                            multiple='false'
                            crop='true'
                            imagesOnly='true' 
                            tabs='file camera gdrive gphotos dropbox instagram onedrive box'
                            clearable='true'
                            debugUploads='true'
                            // onChange={console.log}
                            // onChange={handleImagesChange}
                            onFileSelect={(file) => {
                                if (!file) {
                                console.log("File removed from widget");
                                return;
                                }
                                file.done((fileInfo) => {
                                setImage(fileInfo.cdnUrl);
                                });
                            }}
                        />
                    </div>
                </div>
                <div className='textPrompt'>Tell us about your product...</div>
                <Input
                    type="text"
                    placeholder='What does it smell like?'
                    onChange={(event) => setCaption(event.target.value)}
                    value={caption}
                />
                <Input
                    type="text"
                    placeholder='Volume'
                    onChange={(event) => setVol_num(event.target.value)}
                    value={vol_num}
                />
                <Input
                    type="text"
                    placeholder='Volume Unit'
                    onChange={(event) => setVol_unit(event.target.value)}
                    value={vol_unit}
                />
                <Input
                    type="text"
                    placeholder='Product Name'
                    onChange={(event) => setProduct_name(event.target.value)}
                    value={product_name}
                />
                <Input
                    type="text"
                    placeholder='THC %'
                    onChange={(event) => setThc_lvl(event.target.value)}
                    value={thc_lvl}
                />
                <Input
                    type="text"
                    placeholder='CBD %'
                    onChange={(event) => setCbd_lvl(event.target.value)}
                    value={cbd_lvl}
                />
                <Input
                    type="text"
                    placeholder='Terp %'
                    onChange={(event) => setTerp_lvl(event.target.value)}
                    value={terp_lvl}
                />
                <Button variant="outlined" className='imageupload_button' onClick={createPost}>Create Your Post</Button>
            </form>
        </div>
    )
}

export default ImageUpload
